export enum Url {
  // Token Free
  login = "/test-center/login",
  getProfile = "/getProfile",
  signup = "/user/signup",
  accountRemoval = '/user/accountRemoval',
  verifyPassword = "/user/verifyPassword",

  // patient login
  patientLogin = "/user/mobile-login",
  getAppointment = "/appointment/list",

  // test center
  upcomingAppoinment = "/appointment/upcoming",
  sendOTP = "/user/send-otp",
  verifyOtp = "/test-center/verify-user",
  allReason = "/test/reason/all",
  allTestType = "/test-type/all",
  testTypeMCQ = "/test-type/mcq",
  bookAppoinment = "/appointment/book",
  appoinmentDetail = "/appointment/details?appointmentId=",
  verifyInsurence = "/appointment/verify-insurance",
  cancelAppoinment = "/appointment/changeStatus",
  testResultStatus = "/test-center/changeResultStatus",
  dasboardData = "/test-center/dashboard",
  centreProfile = "/test-center/profile",
  logoutCentre = "/user/logout",
  listAppointment = "/appointment/list",
  uploadReport = "/test-center/uploadResult",
  testResultList = "/appointment/testResult/list",

  // website
  contactUs = "/user/contactUs",
  cityAll = "/test-type/allcity",
  centerByCity = "/test-center/all?city=",
  testtypeByCenter = "/test-type/all?",
  patientProfile = "/user/profile",
  patientForgotPasswordMobile = "/user/forgotPassword/mobile",
  patientForgotPasswordMobileOTP = "/user/forgotPassword/verifyOTP",
  sendPasswordForgotPassword = "/user/setNewPassword",
  patientChangePassword = "/user/changePassword",
  activeAppointments = "/appointment/list",
  sendTestkit = "/appointment/testKitAction",
  promoCodeValidate = "/appointment/validate-promocode",
  // profile
  profileUpdate = "/user/profile",
  getNotifications = "/user/notifications",
  readNotification = "/user/notification",
  paymentIntent = "/appointment/create-intent",
  checkTestKitQRCode = '/facility/checkTestKitQRCode/',
  getSingletestType = '/test-type/details',
  useTestKit = '/facility/useTestKit',
  readAll = '/user/notifications/readAll',
  getClientCompanyEmployerGroup = '/integration/getClientCompanyEmployerGroup',
  claimBenefit = '/integration/claimBenefit',
}
