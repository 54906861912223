import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './examples/landing/landing.component';
import { LoginComponent } from './examples/login/login.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { Path } from './core/enums';
import { AuthguardService } from './guard/authguard.service';
import { PatientSignupComponent } from './components/auth/patient-signup/patient-signup.component';
import { PatientLoginComponent } from './components/auth/patient-login/patient-login.component';

const routes: Routes = [
    // { path: '', redirectTo: '', pathMatch: 'full' },
    // { path: '', component: LandingComponent },
    // {
    //     path: Path.Website,
    //     loadChildren: () => import('./components/website/website.module').then((m) => m.WebsiteModule),
    // },
    {
        path: '',
        loadChildren: () => import('./components/auth/auth.module').then((m) => m.AuthModule),
        canDeactivate: [AuthguardService]
    },
    
    // {
    //     path: Path.SignUpPatient,
    //     component: PatientSignupComponent,
    //     canDeactivate: [AuthguardService]
    // },
    // {
    //     path: Path.Centre,
    //     loadChildren: () => import('./components/web-app/web-app.module').then((m) => m.WebAppModule),
    //     canActivate: [AuthguardService]
    // },
    

    {
        path: '',
        loadChildren: () => import('./components/patient-app/patient-app.module').then((m) => m.PatientAppModule),
        canActivate: [AuthguardService]
    },

    
    {
        path: '',
        redirectTo: Path.Website,
        pathMatch: 'full'
    },

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
