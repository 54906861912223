import { DebugElement, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Auth } from 'aws-amplify';

import { environment } from './../../../environments/environment';
import { Url } from '../../core/enums';
import Utility from '../../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  httpoptions() {

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    }

  }

  private loginDetails;

  getTitle(): string {
    return 'Test11';
  }

  // login
  login(data) {
    return this.http.post(`${environment.apiBaseUrl}${Url.login}`, data, this.httpoptions());
  }

  // patient login
  patientLogin(data) {
    return this.http.post(`${environment.apiBaseUrl}${Url.patientLogin}`, data, this.httpoptions());
  }
  
  signup(data) {
    return this.http.post(`${environment.apiBaseUrl}${Url.signup}`, data, this.httpoptions());
  }

  profileUpdate(data){
    return this.http.patch(`${environment.apiBaseUrl}${Url.profileUpdate}`,data,this.httpoptions());
  }
}


